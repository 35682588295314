<template>
    <div>
    </div>
</template>

<script>

export default {
    name: "Proceeding",
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
        window.location.replace('https://back.uib.kz/common/proceeding.pdf')
    }
}
</script>